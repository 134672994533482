import logo from './intournee_logo.png';
import './App.css';

function App() {
  return (
    <div 
      style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <img src={logo} alt="logo" style={{width: '70%'}} />
    </div>
  );
}

export default App;
